import(/* webpackMode: "eager" */ "/vercel/path0/packages/webb-page/node_modules/@mui/icons-material/School.js");
import(/* webpackMode: "eager" */ "/vercel/path0/packages/webb-page/node_modules/@mui/icons-material/Star.js");
import(/* webpackMode: "eager" */ "/vercel/path0/packages/webb-page/node_modules/@mui/material/Button/index.js");
import(/* webpackMode: "eager" */ "/vercel/path0/packages/webb-page/node_modules/@mui/material/index.js");
import(/* webpackMode: "eager" */ "/vercel/path0/packages/webb-page/node_modules/next/dist/client/link.js");
import(/* webpackMode: "eager" */ "/vercel/path0/packages/webb-page/node_modules/next/dist/client/script.js");
import(/* webpackMode: "eager" */ "/vercel/path0/packages/webb-page/node_modules/next/dist/shared/lib/lazy-dynamic/dynamic-no-ssr.js");
import(/* webpackMode: "eager" */ "/vercel/path0/packages/webb-page/src/app/ClientMenuSwap.js");
import(/* webpackMode: "eager" */ "/vercel/path0/packages/webb-page/src/app/ClientProviders.js");
import(/* webpackMode: "eager" */ "/vercel/path0/packages/webb-page/src/app/Footer.js");
import(/* webpackMode: "eager" */ "/vercel/path0/packages/webb-page/src/app/FullMenu.js");
import(/* webpackMode: "eager" */ "/vercel/path0/packages/webb-page/src/app/HeroWrapper.js");
import(/* webpackMode: "eager" */ "/vercel/path0/packages/webb-page/src/app/new-learning-path/page.js")